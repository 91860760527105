<template>
  <div>
    <busy :visible="loading" />
    <select
      v-if="!loading"
      v-model="selectedCurrency"
      :required="req"
      :disabled="disabled"
      :class="sm ? 'form-control-sm' : ''"
      class="form-control"
    >
      <option
        v-for="currency in $stash.currencies"
        :value="currency"
      >
        {{currency.display_name}}
      </option>
    </select>
  </div>

</template>

<script>
export default {
  props: ["value", "req", "sm", "disabled"],
  data() {
    return {
      selectedCurrency: this.value
    }
  },
  computed: {
    loading() {
      return this.$stash.currencies.length === 0;
    }
  },
  watch: {
    selectedCurrency() {
      this.$emit("input", this.selectedCurrency);
    },
    value() {
      this.selectedCurrency = this.value;
    }
  },
};
</script>

<style>
  .vue-treeselect__control{
    height: 44px;
    padding-top: 4px;
    border: 1px solid #b0bfbf !important;
  }
</style>
