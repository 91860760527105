<template>
  <div v-if="$can('view accounts')">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <!--  -->
          <div class="row mb-4 text-center">
            <div class="col-3 ms-auto text-center border-right">
              <div
                class="card cursor-pointer mb-0"
                :class="
                  showIncome
                    ? 'bg-primary text-white shadow'
                    : 'bg-white text-primary shadow-sm card-hover'
                "
                @click="toggleIncome"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-3 my-auto text-center">
                      <i class="far fa-piggy-bank fa-4x"></i>
                    </div>
                    <div class="col my-auto text-end">
                      <h5 v-if="showGbp" class="mb-1 fw-bold">£{{ gbpIncome }}</h5>
                      <h5 v-if="showEur" class="mb-1 fw-bold">€{{ eurIncome }}</h5>
                      <h6 class="mb-0 font-italic">Income</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3 me-auto text-center">
              <div
                class="card cursor-pointer mb-0"
                :class="
                  showExpenditure
                    ? 'bg-danger text-white shadow'
                    : 'bg-white text-danger shadow-sm card-hover'
                "
                @click="toggleExpenditure"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-3 my-auto text-center">
                      <i class="far fa-money-check fa-4x"></i>
                    </div>
                    <div class="col my-auto text-end">
                      <h5 class="mb-1 fw-bold" v-if="showGbp">£{{ gbpExpenditure }}</h5>
                      <h5 class="mb-1 fw-bold" v-if="showEur">€{{ eurExpenditure }}</h5>
                      <h6 class="mb-0 font-italic">Expenditure</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-auto mx-auto">
          <date-range-picker
            ref="picker"
            :opens="dateRangeConfig.opens"
            :ranges="dateRangeConfig.ranges"
            :show-dropdowns="dateRangeConfig.showDropdowns"
            :locale-data="dateRangeConfig.locale"
            v-model="dateRange"
          >
            <div slot="input" slot-scope="picker">
              <i
                class="far fa-calendar-alt text-success"
                style="margin-right: 3px"
              ></i>
              {{ picker.startDate | date }} - {{ picker.endDate | date }}
            </div>
          </date-range-picker>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="row mb-4" v-if="showExpenditure || showExpenses">
            <div class="col-auto">
              <span
                style="
                  white-space: nowrap;
                  line-height: 30px;
                  padding-right: 10px;
                  font-weight: bold;
                "
                >Record a new manual expense:</span
              >
            </div>
            <div class="col-6 d-flex">
              <input
                type="text"
                placeholder="Expense detail..."
                v-model="expenseDetail"
                class="form-control form-control-sm"
              />
            </div>
            <div class="col-auto">
              <currency-select v-model="expenseCurrency" :sm="true"/>
            </div>
            <div class="col-auto">
              <input
                type="number"
                placeholder="eg. 12.99"
                v-model="expenseAmount"
                class="w-auto me-2 form-control form-control-sm"
              />
            </div>
            <div class="col">
              <button
                class="btn btn-outline-primary btn-sm float-end w-100"
                @click="addExpense"
              >
                <i class="far fa-plus me-2"></i>Save
              </button>
            </div>
          </div>

          <div class="row" id="pdf-container">
            <div class="col">
              <div class="card">
                <div class="card-header pb-0">
                  <div class="row">
                    <div class="col-auto my-auto">
                      <h5 v-if="showAll" class="text-dark my-auto">General Ledger</h5>
                      <h5 v-else-if="showIncome" class="text-dark my-auto">
                        Income
                      </h5>
                      <h5 v-else-if="showExpenditure" class="text-dark my-auto">
                        Expenditure
                      </h5>
                      <h5 v-else-if="showExpenses" class="text-dark my-auto">
                        Expenses
                      </h5>
                      <h5 v-else-if="showSupplierPayments" class="text-dark my-auto">
                        Supplier Payments
                      </h5>
                    </div>
                    <div class="col-auto ms-auto my-auto">
                      <button
                        class="btn btn-outline-dark btn-sm"
                        type="button"
                        @click="exportPDF"
                      >
                        <i class="fa fa-file-pdf"> </i>
                        Export PDF
                      </button>
                      <button
                        class="btn btn-outline-dark btn-sm ms-1"
                        type="button"
                        @click="exportCSV"
                      >
                        <i class="fa fa-file-excel"> </i>
                        Export CSV
                      </button>
                      <div class="dropleft d-inline ms-1">
                        <button
                          class="btn btn-outline-dark btn-sm dropdown-toggle"
                          type="button"
                          id="sortByDropDown"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Sort by
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="sortByDropDown"
                        >
                          <a
                            class="dropdown-item"
                            @click="sortLedgerBy('oldest-newest')"
                            >Oldest to Newest</a
                          >
                          <a
                            class="dropdown-item"
                            @click="sortLedgerBy('newset-oldest')"
                            >Newest to Oldest</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row pdf-only">
                    <h6>
                      <b>Reporting Period:</b>
                      {{ dateRange.startDate | date }} -
                      {{ dateRange.endDate | date }}
                    </h6>
                  </div>
                </div>
                <div class="card-body bg-white">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" width="5%"></th>
                        <th scope="col" width="50%">Transaction</th>
                        <th scope="col">Payment Method</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Date</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="ledgerEntry in currentEntries">
                        <tr
                          @click="toggle(ledgerEntry.id)"
                          :class="{ opened: opened.includes(ledgerEntry.id) }"
                          :key="ledgerEntry.id"
                          class="cursor-pointer"
                        >
                          <td v-if="ledgerEntry.entry_type === 'credit'">
                            <i class="fas fa-plus text-primary"></i>
                            <span class="pdf-only pdf-bold text-success"
                              >+</span
                            >
                          </td>
                          <td v-else>
                            <i class="fas fa-minus text-danger"></i>
                            <span class="pdf-only pdf-bold text-danger">-</span>
                          </td>
                          <td>
                            {{
                              ledgerEntry.payment_type | formatUppercaseWords
                            }}
                            <small
                              v-if="
                                ledgerEntry.payment_type === 'manual_expense'
                              "
                              >- {{ ledgerEntry.details }}</small
                            >
                            <small v-else
                              >(Order #{{ ledgerEntry.order_id }})</small
                            >
                          </td>
                          <td>
                            {{
                              ledgerEntry.payment_method | formatUppercaseWords
                            }}
                          </td>
                          <td v-if="ledgerEntry.entry_type === 'credit'">
                            {{ledgerEntry.price_display}}
                          </td>
                          <td v-else class="text-danger">
                            -{{ledgerEntry.price_display}}
                          </td>
                          <td>
                            {{ ledgerEntry.created_at | formatDate }}
                          </td>
                          <td v-if="opened.includes(ledgerEntry.id)">
                            <i class="fas fa-caret-up text-secondary"></i>
                          </td>
                          <td v-else>
                            <i class="fas fa-caret-down text-secondary"></i>
                          </td>
                        </tr>
                        <tr
                          v-if="opened.includes(ledgerEntry.id)"
                          style="background-color: #edf2f2"
                        >
                          <td></td>
                          <td>
                            <div class="card mb-0">
                              <div class="card-body">
                                <div class="row mb-1">
                                  <div class="col">
                                    <h5 class="text-info">
                                      Transaction Details
                                    </h5>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-5">
                                    <p v-if="ledgerEntry.order" class="mb-1">
                                      Order:
                                      <a
                                        href="#"
                                        @click="goToOrder(ledgerEntry.order_id)"
                                        >#{{ ledgerEntry.order_id }}</a
                                      >
                                    </p>
                                    <p
                                      v-if="
                                        ledgerEntry.order &&
                                        ledgerEntry.payment_type ==
                                          'client_payment'
                                      "
                                      class="mb-1"
                                    >
                                      Client:

                                      <a
                                        href="#"
                                        @click="
                                          goToClient(
                                            ledgerEntry.order.client.id
                                          )
                                        "
                                      >
                                        {{
                                          ledgerEntry.order.client.first_name
                                            | formatUppercaseWords
                                        }}
                                        {{
                                          ledgerEntry.order.client.last_name
                                            | formatUppercaseWords
                                        }}
                                      </a>
                                    </p>
                                    <p
                                      v-if="
                                        ledgerEntry.order &&
                                        ledgerEntry.payment_type ==
                                          'pay_supplier'
                                      "
                                      class="mb-1"
                                    >
                                      Supplier: {{ ledgerEntry.supplier.name }}
                                    </p>
                                    <p class="mb-1">
                                      Payment Method:
                                      {{
                                        ledgerEntry.payment_method
                                          | formatUppercaseWords
                                      }}
                                    </p>
                                    <p v-if="ledgerEntry.details" class="mb-1">
                                      Details: {{ ledgerEntry.details }}
                                    </p>
                                  </div>
                                  <div class="col-7">
                                    <p
                                      v-if="ledgerEntry.stripe_transaction_id"
                                      class="mb-1"
                                    >
                                      Stripe Transaction ID:
                                      <span class="dashed-underline-blue">{{
                                        ledgerEntry.stripe_transaction_id
                                      }}</span>
                                    </p>
                                    <p class="mb-1">
                                      Timestamp:
                                      {{
                                        ledgerEntry.created_at | formatDateTime
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  class="row"
                                  v-if="
                                    ledgerEntry.payment_method ===
                                    'manual_expense'
                                  "
                                >
                                  <div class="col">
                                    <button
                                      class="btn btn-sm btn-outline-danger"
                                      @click="deleteExpense(ledgerEntry)"
                                    >
                                      <i class="far fa-times me-1"></i>
                                      Remove manual expense
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td colspan="5"></td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import CurrencySelect from "../../globals/CurrencySelect";

export default {
  data() {
    return {
      opened: [],
      ledgerItems: [],
      currentEntries: [],
      expenseDetail: "",
      expenseAmount: "",
      expenseCurrency: this.$stash.currencies[0],
      showAll: true,
      showIncome: false,
      showExpenditure: false,
      showExpenses: false,
      showSupplierPayments: false,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      dateRangeConfig: {
        opens: "right",
        showDropdowns: false,
        locale: {
          direction: "ltr", //direction of text
          format: "DD-MM-YYYY", //fomart of the dates displayed
          separator: " - ", //separator between the two ranges
          applyLabel: "Apply",
          cancelLabel: "Cancel",
          weekLabel: "W",
          customRangeLabel: "Custom Range",
          daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
          monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
          firstDay: 1, //ISO first day of week - see moment documenations for details
        },
        ranges: {
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days"),
          ],
          "Last week": [
            moment().subtract(1, "week").startOf("week"),
            moment().subtract(1, "week").endOf("week"),
          ],
          "This month": [moment().startOf("month"), moment().endOf("month")],
          "Last month": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ],
          "This year": [moment().startOf("year"), moment().endOf("year")],
        },
      },
    };
  },
  methods: {
    openPicker(id) {
      var datePicker = document.querySelector(id);
      datePicker.click();
    },
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },
    toggleIncome() {
      this.showIncome = !this.showIncome;
      if (this.showIncome) {
        this.showAll = false;
        this.showExpenditure = false;
        this.showExpenses = false;
        this.showSupplierPayments = false;
        this.currentEntries = this.ledgerCredits;
      } else {
        this.showAll = true;
        this.showExpenditure = false;
        this.showExpenses = false;
        this.showSupplierPayments = false;
        this.currentEntries = this.ledgerItems;
      }
    },
    toggleExpenditure() {
      this.showExpenditure = !this.showExpenditure;
      if (this.showExpenditure) {
        this.showAll = false;
        this.showIncome = false;
        this.showExpenses = false;
        this.showSupplierPayments = false;
        this.currentEntries = this.ledgerDebits;
      } else {
        this.showAll = true;
        this.showIncome = false;
        this.currentEntries = this.ledgerItems;
      }
    },
    toggleExpenses() {
      this.showExpenses = !this.showExpenses;
      if (this.showExpenses) {
        this.showAll = false;
        this.showIncome = false;
        this.showExpenditure = false;
        this.showSupplierPayments = false;
        this.currentEntries = this.ledgerExpenses;
      } else {
        this.showAll = true;
        this.showIncome = false;
        this.showExpenditure = false;
        this.showSupplierPayments = false;
        this.currentEntries = this.ledgerItems;
      }
    },
    toggleSupplierPayments() {
      this.showSupplierPayments = !this.showSupplierPayments;
      if (this.showSupplierPayments) {
        this.showAll = false;
        this.showIncome = false;
        this.showExpenditure = false;
        this.showExpenses = false;
        this.currentEntries = this.ledgerSupplierPayments;
      } else {
        this.showAll = true;
        this.showIncome = false;
        this.showExpenditure = false;
        this.showExpenses = false;
        this.currentEntries = this.ledgerItems;
      }
    },
    addExpense() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/accounts/add-expense", {
          detail: this.expenseDetail,
          amount: this.expenseAmount,
          currency_id: this.expenseCurrency.id
        })
        .then(({ data }) => {
          this.fetchLedger();
          this.expenseDetail = "";
          this.expenseAmount = "";
          this.$EventBus.$emit("alert", data);
        });
    },
    deleteExpense(ledgerEntry) {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/accounts/delete-expense", {
          ledger_entry_id: ledgerEntry.id,
        })
        .then(({ data }) => {
          this.fetchLedger();
          this.$EventBus.$emit("alert", data);
        });
    },
    fetchLedger() {
      //load form data json from backend for given date range
      var start = moment(this.dateRange.startDate);
      var end = moment(this.dateRange.endDate);

      return this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/accounts/fetch-ledger?start=" +
            start +
            "&end=" +
            end
        )
        .then(({ data }) => {
          this.ledgerItems = data;
          this.refreshCurrentEntries();
        });
    },
    refreshCurrentEntries() {
      if (this.showIncome) {
        this.currentEntries = this.ledgerCredits;
      } else if (this.showExpenditure) {
        this.currentEntries = this.ledgerDebits;
      } else if (this.showExpenses) {
        this.currentEntries = this.ledgerExpenses;
      } else if (this.showSupplierPayments) {
        this.currentEntries = this.ledgerSupplierPayments;
      } else {
        this.currentEntries = this.ledgerItems;
      }
    },
    exportPDF() {
      let data = {
        html: document.getElementById("pdf-container").outerHTML,
      };
      return this.$axios
        .post(process.env.VUE_APP_API_URL + "/accounts/export-pdf", data, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "export.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
    },
    exportCSV() {
      //load form data json from backend for given date range
      var start = moment(this.dateRange.startDate);
      var end = moment(this.dateRange.endDate);

      return this.$axios
        .get(
            process.env.VUE_APP_API_URL +
            "/accounts/export-csv?start=" +
            start +
            "&end=" +
            end
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "export.csv");
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
    },
    goToClient(clientId) {
      //redirect to client forms section
      this.$router.push("clients/" + clientId);
    },
    goToOrder(orderId) {
      //redirect to order page
      this.$router.push("orders/" + orderId);
    },
    sortLedgerBy(sort) {
      //sort current list of entries
      if (sort == "oldest-newest") {
        this.currentEntries.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
      } else if (sort == "newset-oldest") {
        this.currentEntries.sort(
          (b, a) => new Date(a.created_at) - new Date(b.created_at)
        );
      }
    },
  },
  computed: {
    ledgerCredits: function () {
      return this.ledgerItems.filter(function (u) {
        return u.entry_type === "credit";
      });
    },
    ledgerDebits: function () {
      return this.ledgerItems.filter(function (u) {
        return u.entry_type === "debit";
      });
    },
    ledgerSupplierPayments: function () {
      return this.ledgerItems.filter(function (u) {
        return u.payment_type === "pay_supplier";
      });
    },
    ledgerExpenses: function () {
      return this.ledgerItems.filter(function (u) {
        return u.payment_type === "manual_expense";
      });
    },
    totalIncome: function () {
      return Object.values(this.ledgerCredits)
        .reduce((t, { amount }) => t + amount, 0)
        .toFixed(2);
    },
    totalExpenditure: function () {
      return Object.values(this.ledgerDebits)
        .reduce((t, { amount }) => t + amount, 0)
        .toFixed(2);
    },
    gbpIncome: function () {
      return Object.values(this.ledgerCredits)
          .filter((item) => item.currency_id === 1)
          .reduce((t, { amount }) => t + amount, 0)
          .toFixed(2);
    },
    gbpExpenditure: function () {
      return Object.values(this.ledgerDebits)
          .filter((item) => item.currency_id === 1)
          .reduce((t, { amount }) => t + amount, 0)
          .toFixed(2);
    },
    eurIncome: function () {
      return Object.values(this.ledgerCredits)
          .filter((item) => item.currency_id === 2)
          .reduce((t, { amount }) => t + amount, 0)
          .toFixed(2);
    },
    eurExpenditure: function () {
      return Object.values(this.ledgerDebits)
          .filter((item) => item.currency_id === 2)
          .reduce((t, { amount }) => t + amount, 0)
          .toFixed(2);
    },
    totalExpenses: function () {
      return Object.values(this.ledgerExpenses)
        .reduce((t, { amount }) => t + amount, 0)
        .toFixed(2);
    },
    totalSupplierPayments: function () {
      return Object.values(this.ledgerSupplierPayments)
        .reduce((t, { amount }) => t + amount, 0)
        .toFixed(2);
    },
    showGbp: function () {
      return this.ledgerItems.filter((item) => item.currency_id === 1).length > 0
    },
    showEur: function () {
      return this.ledgerItems.filter((item) => item.currency_id === 2).length > 0
    },
  },
  watch: {
    dateRange: function (newDate, oldDate) {
      this.fetchLedger();
    },
  },
  mounted() {
    this.dateRange.startDate = moment("01-01-1970", "MM-DD-YYYY");
    this.dateRange.endDate = moment();

    this.fetchLedger().then(() => {
      this.currentEntries = this.ledgerItems;
    });
  },
  filters: {
    date(value) {
      let options = { year: "numeric", month: "long", day: "numeric" };
      return Intl.DateTimeFormat("en-US", options).format(value);
    },
    formatDate(date) {
      return moment(date).format("Do MMM YYYY");
    },
    formatDateTime(date) {
      return moment(date).format("Do MMM YYYY HH:MM");
    },
    formatUppercaseWords(type) {
      return type.replace("_", " ").replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
  },
  components: {
    CurrencySelect,
    DateRangePicker,
  },
};
</script>
